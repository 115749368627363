import React from 'react';
import Button from 'react-bootstrap/Button';

export default (props) => (
  <Button
    {...props}
    className="clear form-widget__filter-mobile-input-close-container"
    variant="link"
    aria-label="Clear"
  >
    <div />
    <div />
  </Button>
);
