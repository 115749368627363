import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import classnames from 'classnames';

const ZIP_LENGTH = 5;

const FormInput = React.forwardRef(
  ({ className, controlId, error, fakeError, label, onChange, placeholder, type, value, ...props }, ref) => {
    let modOnChange = onChange,
      modType = type;

    if (type === 'zip') {
      modOnChange = (e) => {
        if (e.target.value.length > ZIP_LENGTH) {
          return;
        }

        if (e.target.value === '' || /^[0-9]+$/.test(e.target.value)) onChange(e);
      };

      modType = 'text';
    }

    return (
      <Form.Group controlId={controlId} className="w-100 position-relative d-block custom-input-container">
        <Form.Label className="custom-input__placeholder--active" srOnly={!value}>
          {label || placeholder}
        </Form.Label>
        <Form.Control
          className={classnames(
            'custom-input',
            className,
            value && 'active',
            (error || (fakeError && !value)) && 'has-error'
          )}
          onChange={modOnChange}
          placeholder={placeholder}
          ref={ref}
          type={modType}
          value={value}
          {...props}
        />
        {error && <p className="has-error fadeIn">{error}</p>}
        {fakeError && !value && <p className="has-error fadeIn">{fakeError}</p>}
      </Form.Group>
    );
  }
);

export default FormInput;

FormInput.propTypes = {
  controlId: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};
