import React, { useEffect, useReducer } from 'react';
import Submit from './Submit';

const Form = ({
  children,
  formProps = {},
  values,
  isValid,
  onSubmit,
  onSuccess,
  customSubmit = false,
  submitText,
  submitProps,
  showTextWhileLoading = false,
  errorsMessages = {},
}) => {
  const initialState = {
    form: values,
    formSubmitStatus: 'new',
    errors: {},
    valid: false,
  };

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case 'UPDATE_FORM':
        return { ...state, form: { ...state.form, ...payload } };
      case 'UPDATE_ERRORS':
        return { ...state, errors: { ...state.errors, ...payload } };
      case 'UPDATE_FORM_SUBMIT_STATUS':
        return { ...state, formSubmitStatus: payload };
      case 'UPDATE_FORM_VALID':
        return { ...state, valid: payload };
      case 'RESET_FORM':
        return { ...state, form: initialState.form, formSubmitStatus: 'new', errors: {}, valid: false };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!values) return;

    dispatch({ type: 'UPDATE_FORM', payload: values });
  }, [values]);

  useEffect(() => {
    if (state.formSubmitStatus === 'submitted') {
      setTimeout(() => {
        dispatch({ type: 'RESET_FORM' });
      }, 400);
    }
  }, [state.formSubmitStatus]);

  const onChange = ({ target: { value, name } }) => {
    const { errors } = state;

    if (value && errors[name]) dispatch({ type: 'UPDATE_ERRORS', payload: { [name]: null } });

    if (isValid({ name, value })) dispatch({ type: 'UPDATE_FORM_VALID', payload: true });
    else dispatch({ type: 'UPDATE_FORM_VALID', payload: false });

    dispatch({ type: 'UPDATE_FORM', payload: { [name]: value } });
  };

  const resetField = ({ name, targetRef }) => {
    dispatch({ type: 'UPDATE_ERRORS', payload: { [name]: null } });
    dispatch({ type: 'UPDATE_FORM', payload: { [name]: '' } });
    targetRef?.current && targetRef.current.focus();
  };

  const throwFormError = (payload) => {
    dispatch({ type: 'UPDATE_FORM_SUBMIT_STATUS', payload: 'new' });
    dispatch({ type: 'UPDATE_ERRORS', payload });
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    dispatch({ type: 'UPDATE_FORM_SUBMIT_STATUS', payload: 'submitting' });

    if (!state.valid) return dispatch({ type: 'UPDATE_FORM_SUBMIT_STATUS', payload: 'new' });

    let res;

    try {
      res = await onSubmit(state.form);
    } catch (e) {
      // error handle here
      return throwFormError();
    }

    if (res.error) {
      return throwFormError(res.payload);
    }

    dispatch({ type: 'UPDATE_FORM_SUBMIT_STATUS', payload: 'submitted' });
    onSuccess && onSuccess(state.form);
  };

  const { formSubmitStatus, errors, form, valid } = state,
    params = { values: form, onChange, errors, formSubmitStatus, resetField };

  return (
    <form onSubmit={onSubmitForm} {...formProps}>
      {children(params)}
      {!customSubmit && (
        <Submit
          showTextWhileLoading={showTextWhileLoading}
          status={formSubmitStatus}
          disabled={!valid}
          {...submitProps}
        >
          {submitText}
        </Submit>
      )}
    </form>
  );
};

export default Form;
